@keyframes loading-animation {
  10% {
    left: 0;
   width: 0;
  }
  50%   {
    left: 0;
    width: 100%;
  }
  90% {
    left: 100%;
    width: 0;
  }
}

.Loading {
  position: relative;

  &::before {
    display: block;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 0;
    height: 2px;
    background-color: rgb(240, 48, 64); // "red" in the palette
    animation: loading-animation 3s ease-in-out 1s infinite;
  }

  // Top-level shows up at the top of the screen
  &--toplevel::before {
    position: fixed;
    z-index: 9999;
    height: 4px;
  }
}