@font-face {
  font-family: "ABBvoice";
  font-style: normal;
  font-weight: 100;
  src: url("../assets/fonts/ABBvoice_W_Lt.eot");
  src: url("../assets/fonts/ABBvoice_W_Lt.eot?#iefix") format("embedded-opentype"), url("../assets/fonts/ABBvoice_W_Lt.woff") format("woff"), url("../assets/fonts/ABBvoice_W_Lt.woff2") format("woff2"); }

@font-face {
  font-family: "ABBvoice";
  font-style: normal;
  font-weight: 400;
  src: url("../assets/fonts/ABBvoice_W_Rg.eot");
  src: url("../assets/fonts/ABBvoice_W_Rg.eot?#iefix") format("embedded-opentype"), url("../assets/fonts/ABBvoice_W_Rg.woff") format("woff"), url("../assets/fonts/ABBvoice_W_Rg.woff2") format("woff2"); }

@font-face {
  font-family: "ABBvoice";
  font-style: normal;
  font-weight: 500;
  src: url("../assets/fonts/ABBvoice_W_Md.eot");
  src: url("../assets/fonts/ABBvoice_W_Md.eot?#iefix") format("embedded-opentype"), url("../assets/fonts/ABBvoice_W_Md.woff") format("woff"), url("../assets/fonts/ABBvoice_W_Md.woff2") format("woff2"); }

@font-face {
  font-family: "ABBvoice";
  font-style: normal;
  font-weight: 700;
  src: url("../assets/fonts/ABBvoice_W_Bd.eot");
  src: url("../assets/fonts/ABBvoice_W_Bd.eot?#iefix") format("embedded-opentype"), url("../assets/fonts/ABBvoice_W_Bd.woff") format("woff"), url("../assets/fonts/ABBvoice_W_Bd.woff2") format("woff2"); }
