
.PowerScreen {
  .ABB_CommonUX_NumericInput__root > .ABB_CommonUX_NumericInput__container {
    height: 38px;

    > .ABB_CommonUX_NumericInput__inputWrapper > input {
      font-size: 16px;
      font-family: inherit;
    }

    > .ABB_CommonUX_NumericInput__buttons {
      > .ABB_CommonUX_NumericInput__up, .ABB_CommonUX_NumericInput__down {
        height: 15px;
      }
    }
  }
}