.ErrorLabel {
  display: flex;
  flex-direction: row;
  height: auto;
  margin-top: var(--spacing-1);
  color: var(--color-grey-70);
  align-items: center;

  &-Icon {
    color: var(--color-status-red);
    margin-right: var(--spacing-1);
    color: red;
  }

  &-Message {
    font-weight: 500;
    font-size: 12px;
    margin-left: 4px;
    margin-bottom: 4px;  
    color: #f03040;
  }
}
