.StartScreen {
  position: relative;
  z-index: 1;
  /* Fallback */
  min-height: 100vh;
  /* Footer is 32px (2rem) and header is 48px (3rem) plus mysterious 2px making it 3,125 */
  min-height: calc(100vh - 2rem - 3.125rem);

  /*  */
  &::after {
    position: absolute;
    content: '';
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    background-position: center;
    background-repeat: no-repeat;
    background-size: auto;
    opacity: 0.6;
    
    @media screen {
      background-image: url("/assets/img/start-background-large.jpg");
    }
    
    @media screen and (max-width: 768px) {
      background-image: url("/assets/img/start-background-small.jpg");
      background-position-y: 150px;
    }
  }
}