// The structure of rc-checkbox is
// - span.rc-checkbox, root element which can have `.rc-checkbox-checked``
//  - input.rc-checkbox-input, hidden
//  - span.rc-checkbox-inner, checkbox ui, outer red circle
//    - ::after, inner red circle
.rc-checkbox {
  &-inner {
    border-radius: 100%;
    border-width: 2px;
    // TODO: SASS color variables!!
    // color-grey-30
    border-color: rgb(186, 186, 186);
    background-color: transparent;
  }

  &:hover &-inner, &-checked &-inner {
    // color-red
    border-color: rgb(240, 48, 64);
    background-color: transparent;
  }


  &-inner::after, &-checked &-inner::after {
    transition: opacity 0.3s;
    // color-red
    background-color: rgb(240, 48, 64);
    border-radius: 100%;
    top: 18%;
    right: 18%;
    bottom: 18%;
    left: 18%;
    display: block;
    width: auto;
    height: auto;
    border: none;
    animation: none;
    transform: none;
  }
  &-inner::after {opacity: 0;}

  &-checked &-inner::after {
    opacity: 1;
  }
}