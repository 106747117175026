.CategorySelection {
  svg { fill: currentColor; }

  &-Item {
     &:hover, &--selected {
      color: var(--color-grey-90);
    }

    &:hover svg, &--selected svg {
      color: var(--color-red);
    }

    &--selected { font-weight: 500; }

    button {
      color: inherit;
      font-family: inherit;
      font-weight: inherit;
    }
  }
}
