@font-face {
  font-family: 'ABB-UI-Icons-32x32';
  src:  url('../assets/fonts/ABB-UI-Icons-32x32.eot?lel6dg');
  src:  url('../assets/fonts/ABB-UI-Icons-32x32.eot?lel6dg#iefix') format('embedded-opentype'),
    url('../assets/fonts/ABB-UI-Icons-32x32.woff2?lel6dg') format('woff2'),
    url('../assets/fonts/ABB-UI-Icons-32x32.ttf?lel6dg') format('truetype'),
    url('../assets/fonts/ABB-UI-Icons-32x32.woff?lel6dg') format('woff'),
    url('../assets/fonts/ABB-UI-Icons-32x32.svg?lel6dg#ABB-UI-Icons-32x32') format('svg');
  font-weight: normal;
  font-style: normal;
}

.icon_abb_32 {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'ABB-UI-Icons-32x32' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-abb_add-user_32:before {
  content: "\e900";
}
.icon-abb_alarm-bell_32:before {
  content: "\e901";
}
.icon-abb_align-bottom_32:before {
  content: "\e902";
}
.icon-abb_align-horizontal-center_32:before {
  content: "\e903";
}
.icon-abb_align-left_32:before {
  content: "\e904";
}
.icon-abb_align-right_32:before {
  content: "\e905";
}
.icon-abb_align-top_32:before {
  content: "\e906";
}
.icon-abb_align-vertical-center_32:before {
  content: "\e907";
}
.icon-abb_analyze-document_32:before {
  content: "\e908";
}
.icon-abb_anchor_32:before {
  content: "\e909";
}
.icon-abb_audio-off_32:before {
  content: "\e90a";
}
.icon-abb_audio-on_32:before {
  content: "\e90b";
}
.icon-abb_background_32:before {
  content: "\e90c";
}
.icon-abb_bar-chart_32:before {
  content: "\e90d";
}
.icon-abb_battery-charging_32:before {
  content: "\e90e";
}
.icon-abb_battery-empty_32:before {
  content: "\e90f";
}
.icon-abb_battery-full_32:before {
  content: "\e910";
}
.icon-abb_battery-half_32:before {
  content: "\e911";
}
.icon-abb_battery-high_32:before {
  content: "\e912";
}
.icon-abb_battery-low_32:before {
  content: "\e913";
}
.icon-abb_binary-data_32:before {
  content: "\e914";
}
.icon-abb_bluetooth_32:before {
  content: "\e915";
}
.icon-abb_boolean_32:before {
  content: "\e916";
}
.icon-abb_briefcase_32:before {
  content: "\e917";
}
.icon-abb_bring-to-front_32:before {
  content: "\e918";
}
.icon-abb_broadcast_32:before {
  content: "\e919";
}
.icon-abb_calculator_32:before {
  content: "\e91a";
}
.icon-abb_calendar_32:before {
  content: "\e91b";
}
.icon-abb_call_32:before {
  content: "\e91c";
}
.icon-abb_camera_32:before {
  content: "\e91d";
}
.icon-abb_cart_32:before {
  content: "\e91e";
}
.icon-abb_certificate-document-1_32:before {
  content: "\e91f";
}
.icon-abb_certificate-document-2_32:before {
  content: "\e920";
}
.icon-abb_chat_32:before {
  content: "\e921";
}
.icon-abb_check-mark_32:before {
  content: "\e922";
}
.icon-abb_check-mark-circle-1_32:before {
  content: "\e923";
}
.icon-abb_check-mark-circle-2_32:before {
  content: "\e924";
}
.icon-abb_close_32:before {
  content: "\e925";
}
.icon-abb_comment_32:before {
  content: "\e926";
}
.icon-abb_computer_32:before {
  content: "\e927";
}
.icon-abb_connect_32:before {
  content: "\e928";
}
.icon-abb_controller-cabinet_32:before {
  content: "\e929";
}
.icon-abb_controls-and-settings_32:before {
  content: "\e92a";
}
.icon-abb_copy_32:before {
  content: "\e92b";
}
.icon-abb_cut_32:before {
  content: "\e92c";
}
.icon-abb_dashboard_32:before {
  content: "\e92d";
}
.icon-abb_database_32:before {
  content: "\e92e";
}
.icon-abb_database-new_32:before {
  content: "\e92f";
}
.icon-abb_decimal_32:before {
  content: "\e930";
}
.icon-abb_devices_32:before {
  content: "\e931";
}
.icon-abb_disconnect_32:before {
  content: "\e932";
}
.icon-abb_distribution_32:before {
  content: "\e933";
}
.icon-abb_document_32:before {
  content: "\e934";
}
.icon-abb_dollar_32:before {
  content: "\e935";
}
.icon-abb_double-down_32:before {
  content: "\e936";
}
.icon-abb_double-left_32:before {
  content: "\e937";
}
.icon-abb_double-right_32:before {
  content: "\e938";
}
.icon-abb_double-up_32:before {
  content: "\e939";
}
.icon-abb_down_32:before {
  content: "\e93a";
}
.icon-abb_down-arrow_32:before {
  content: "\e93b";
}
.icon-abb_download_32:before {
  content: "\e93c";
}
.icon-abb_drive_32:before {
  content: "\e93d";
}
.icon-abb_edit_32:before {
  content: "\e93e";
}
.icon-abb_end-call_32:before {
  content: "\e93f";
}
.icon-abb_envelope_32:before {
  content: "\e940";
}
.icon-abb_error-circle-1_32:before {
  content: "\e941";
}
.icon-abb_error-circle-2_32:before {
  content: "\e942";
}
.icon-abb_euro_32:before {
  content: "\e943";
}
.icon-abb_event_32:before {
  content: "\e944";
}
.icon-abb_export_32:before {
  content: "\e945";
}
.icon-abb_factory_32:before {
  content: "\e946";
}
.icon-abb_filter_32:before {
  content: "\e947";
}
.icon-abb_folder_32:before {
  content: "\e948";
}
.icon-abb_folder-new_32:before {
  content: "\e949";
}
.icon-abb_folder-open_32:before {
  content: "\e94a";
}
.icon-abb_forward_32:before {
  content: "\e94b";
}
.icon-abb_fullscreen_32:before {
  content: "\e94c";
}
.icon-abb_gauge_32:before {
  content: "\e94d";
}
.icon-abb_gbp_32:before {
  content: "\e94e";
}
.icon-abb_globe_32:before {
  content: "\e94f";
}
.icon-abb_group_32:before {
  content: "\e950";
}
.icon-abb_help-circle-1_32:before {
  content: "\e951";
}
.icon-abb_help-circle-2_32:before {
  content: "\e952";
}
.icon-abb_hide_32:before {
  content: "\e953";
}
.icon-abb_history_32:before {
  content: "\e954";
}
.icon-abb_home_32:before {
  content: "\e955";
}
.icon-abb_hour-glass_32:before {
  content: "\e956";
}
.icon-abb_image_32:before {
  content: "\e957";
}
.icon-abb_import_32:before {
  content: "\e958";
}
.icon-abb_information-circle-1_32:before {
  content: "\e959";
}
.icon-abb_information-circle-2_32:before {
  content: "\e95a";
}
.icon-abb_key_32:before {
  content: "\e95b";
}
.icon-abb_label_32:before {
  content: "\e95c";
}
.icon-abb_left_32:before {
  content: "\e95d";
}
.icon-abb_left-arrow_32:before {
  content: "\e95e";
}
.icon-abb_level_32:before {
  content: "\e95f";
}
.icon-abb_light-bulb_32:before {
  content: "\e960";
}
.icon-abb_link_32:before {
  content: "\e961";
}
.icon-abb_list_32:before {
  content: "\e962";
}
.icon-abb_location_32:before {
  content: "\e963";
}
.icon-abb_lock-closed_32:before {
  content: "\e964";
}
.icon-abb_lock-open_32:before {
  content: "\e965";
}
.icon-abb_log-in_32:before {
  content: "\e966";
}
.icon-abb_log-out_32:before {
  content: "\e967";
}
.icon-abb_magnet_32:before {
  content: "\e968";
}
.icon-abb_make-same-height_32:before {
  content: "\e969";
}
.icon-abb_make-same-size_32:before {
  content: "\e96a";
}
.icon-abb_make-same-width_32:before {
  content: "\e96b";
}
.icon-abb_map_32:before {
  content: "\e96c";
}
.icon-abb_matrix_32:before {
  content: "\e96d";
}
.icon-abb_maximize_32:before {
  content: "\e96e";
}
.icon-abb_menu_32:before {
  content: "\e96f";
}
.icon-abb_menu-narrow_32:before {
  content: "\e970";
}
.icon-abb_microphone_32:before {
  content: "\e971";
}
.icon-abb_microphone-off_32:before {
  content: "\e972";
}
.icon-abb_minimize_32:before {
  content: "\e973";
}
.icon-abb_minus_32:before {
  content: "\e974";
}
.icon-abb_minus-in-circle_32:before {
  content: "\e975";
}
.icon-abb_missed-call_32:before {
  content: "\e976";
}
.icon-abb_moon_32:before {
  content: "\e977";
}
.icon-abb_more_32:before {
  content: "\e978";
}
.icon-abb_motor_32:before {
  content: "\e979";
}
.icon-abb_network_32:before {
  content: "\e97a";
}
.icon-abb_new-document_32:before {
  content: "\e97b";
}
.icon-abb_numericals_32:before {
  content: "\e97c";
}
.icon-abb_object_32:before {
  content: "\e97d";
}
.icon-abb_object-container-1_32:before {
  content: "\e97e";
}
.icon-abb_object-container-2_32:before {
  content: "\e97f";
}
.icon-abb_open-in-new-window_32:before {
  content: "\e980";
}
.icon-abb_panel_32:before {
  content: "\e981";
}
.icon-abb_paper-clip_32:before {
  content: "\e982";
}
.icon-abb_paste_32:before {
  content: "\e983";
}
.icon-abb_pause_32:before {
  content: "\e984";
}
.icon-abb_pie-chart_32:before {
  content: "\e985";
}
.icon-abb_pin_32:before {
  content: "\e986";
}
.icon-abb_play_32:before {
  content: "\e987";
}
.icon-abb_plugin_32:before {
  content: "\e988";
}
.icon-abb_plus_32:before {
  content: "\e989";
}
.icon-abb_plus-in-circle_32:before {
  content: "\e98a";
}
.icon-abb_pop-out-window_32:before {
  content: "\e98b";
}
.icon-abb_power-on-off_32:before {
  content: "\e98c";
}
.icon-abb_pressure_32:before {
  content: "\e98d";
}
.icon-abb_print_32:before {
  content: "\e98e";
}
.icon-abb_pump_32:before {
  content: "\e98f";
}
.icon-abb_qr-code_32:before {
  content: "\e990";
}
.icon-abb_rearrange_32:before {
  content: "\e991";
}
.icon-abb_redo_32:before {
  content: "\e992";
}
.icon-abb_refresh_32:before {
  content: "\e993";
}
.icon-abb_reports_32:before {
  content: "\e994";
}
.icon-abb_resistor_32:before {
  content: "\e995";
}
.icon-abb_rewind_32:before {
  content: "\e996";
}
.icon-abb_right_32:before {
  content: "\e997";
}
.icon-abb_right-arrow_32:before {
  content: "\e998";
}
.icon-abb_robot_32:before {
  content: "\e999";
}
.icon-abb_robot-cabinet_32:before {
  content: "\e99a";
}
.icon-abb_robot-tool_32:before {
  content: "\e99b";
}
.icon-abb_rotate-left-90_32:before {
  content: "\e99c";
}
.icon-abb_rotate-left-180_32:before {
  content: "\e99d";
}
.icon-abb_rotate-right-270_32:before {
  content: "\e99e";
}
.icon-abb_rotate-left-270_321:before {
  content: "\e99f";
}
.icon-abb_rotate-right-90_32:before {
  content: "\e9a0";
}
.icon-abb_rotate-right-180_32:before {
  content: "\e9a1";
}
.icon-abb_save_32:before {
  content: "\e9a2";
}
.icon-abb_save-as_32:before {
  content: "\e9a3";
}
.icon-abb_screen_32:before {
  content: "\e9a4";
}
.icon-abb_search_32:before {
  content: "\e9a5";
}
.icon-abb_send-to-back_32:before {
  content: "\e9a6";
}
.icon-abb_server_32:before {
  content: "\e9a7";
}
.icon-abb_settings_32:before {
  content: "\e9a8";
}
.icon-abb_share_32:before {
  content: "\e9a9";
}
.icon-abb_shortcut_32:before {
  content: "\e9aa";
}
.icon-abb_shuffle_32:before {
  content: "\e9ab";
}
.icon-abb_skip-to-end_32:before {
  content: "\e9ac";
}
.icon-abb_skip-to-start_32:before {
  content: "\e9ad";
}
.icon-abb_sld-1_32:before {
  content: "\e9ae";
}
.icon-abb_sld-2_32:before {
  content: "\e9af";
}
.icon-abb_smartphone_32:before {
  content: "\e9b0";
}
.icon-abb_sort-alphabet-down_32:before {
  content: "\e9b1";
}
.icon-abb_sort-alphabet-up_32:before {
  content: "\e9b2";
}
.icon-abb_sort-date-down-1_32:before {
  content: "\e9b3";
}
.icon-abb_sort-date-down-2_32:before {
  content: "\e9b4";
}
.icon-abb_sort-date-up-1_32:before {
  content: "\e9b5";
}
.icon-abb_sort-date-up-2_32:before {
  content: "\e9b6";
}
.icon-abb_specification_32:before {
  content: "\e9b7";
}
.icon-abb_stairs_32:before {
  content: "\e9b8";
}
.icon-abb_star_32:before {
  content: "\e9b9";
}
.icon-abb_stop_32:before {
  content: "\e9ba";
}
.icon-abb_sun_32:before {
  content: "\e9bb";
}
.icon-abb_sun-2_32:before {
  content: "\e9bc";
}
.icon-abb_synchronize_32:before {
  content: "\e9bd";
}
.icon-abb_system_32:before {
  content: "\e9be";
}
.icon-abb_table_32:before {
  content: "\e9bf";
}
.icon-abb_temperature_32:before {
  content: "\e9c0";
}
.icon-abb_temperature-celsius-1_32:before {
  content: "\e9c1";
}
.icon-abb_temperature-celsius-2_32:before {
  content: "\e9c2";
}
.icon-abb_temperature-fahrenheit-1_32:before {
  content: "\e9c3";
}
.icon-abb_temperature-fahrenheit-2_32:before {
  content: "\e9c4";
}
.icon-abb_template_32:before {
  content: "\e9c5";
}
.icon-abb_template-new_32:before {
  content: "\e9c6";
}
.icon-abb_test_32:before {
  content: "\e9c7";
}
.icon-abb_text_32:before {
  content: "\e9c8";
}
.icon-abb_time_32:before {
  content: "\e9c9";
}
.icon-abb_time-and-date_32:before {
  content: "\e9ca";
}
.icon-abb_track-1_32:before {
  content: "\e9cb";
}
.icon-abb_track-2_32:before {
  content: "\e9cc";
}
.icon-abb_transformer_32:before {
  content: "\e9cd";
}
.icon-abb_trash_32:before {
  content: "\e9ce";
}
.icon-abb_tree-view_32:before {
  content: "\e9cf";
}
.icon-abb_trend-1_32:before {
  content: "\e9d0";
}
.icon-abb_trend-2_32:before {
  content: "\e9d1";
}
.icon-abb_turbocharger_32:before {
  content: "\e9d2";
}
.icon-abb_undo_32:before {
  content: "\e9d3";
}
.icon-abb_unpin_32:before {
  content: "\e9d4";
}
.icon-abb_up_32:before {
  content: "\e9d5";
}
.icon-abb_up-arrow_32:before {
  content: "\e9d6";
}
.icon-abb_upload_32:before {
  content: "\e9d7";
}
.icon-abb_usb_32:before {
  content: "\e9d8";
}
.icon-abb_user_32:before {
  content: "\e9d9";
}
.icon-abb_user-in-circle_32:before {
  content: "\e9da";
}
.icon-abb_video-camera-off_32:before {
  content: "\e9db";
}
.icon-abb_video-camera-on_32:before {
  content: "\e9dc";
}
.icon-abb_video-chat_32:before {
  content: "\e9dd";
}
.icon-abb_view_32:before {
  content: "\e9de";
}
.icon-abb_voltage_32:before {
  content: "\e9df";
}
.icon-abb_warning-circle-1_32:before {
  content: "\e9e0";
}
.icon-abb_warning-circle-2_32:before {
  content: "\e9e1";
}
.icon-abb_warning-triangle_32:before {
  content: "\e9e2";
}
.icon-abb_wrench_32:before {
  content: "\e9e3";
}
.icon-abb_zoom-in_32:before {
  content: "\e9e4";
}
.icon-abb_zoom-out_32:before {
  content: "\e9e5";
}
.icon-abb_trend-3_32:before {
  content: "\e9e6";
}
.icon-abb_trend-scatter_32:before {
  content: "\e9e7";
}
.icon-abb_sort-ascending_32:before {
  content: "\e9e8";
}
.icon-abb_sort-descending_32:before {
  content: "\e9e9";
}
.icon-abb_advanced-settings_32:before {
  content: "\e9ea";
}
.icon-abb_align_32:before {
  content: "\e9eb";
}
.icon-abb_robot-axis_32:before {
  content: "\e9ec";
}
.icon-abb_calibration_32:before {
  content: "\e9ed";
}
.icon-abb_connection_32:before {
  content: "\e9ee";
}
.icon-abb_network-status_32:before {
  content: "\e9ef";
}
.icon-abb_create-backup_32:before {
  content: "\e9f0";
}
.icon-abb_data-editor_32:before {
  content: "\e9f1";
}
.icon-abb_execution-32:before {
  content: "\e9f2";
}
.icon-abb_hardware-devices_32:before {
  content: "\e9f3";
}
.icon-abb_io-devices_32:before {
  content: "\e9f4";
}
.icon-abb_joystick_32:before {
  content: "\e9f5";
}
.icon-abb_lead-through_32:before {
  content: "\e9f6";
}
.icon-abb_jog-linear_32:before {
  content: "\e9f7";
}
.icon-abb_document-excel_32:before {
  content: "\e9f8";
}
.icon-abb_document-pdf_32:before {
  content: "\e9f9";
}
.icon-abb_document-word_32:before {
  content: "\e9fa";
}
.icon-abb_modify-rapid-position_32:before {
  content: "\e9fb";
}
.icon-abb_software-module_32:before {
  content: "\e9fc";
}
.icon-abb_network-status-1_32:before {
  content: "\e9fe";
}
.icon-abb_network-status-2_32:before {
  content: "\e9ff";
}
.icon-abb_payload_32:before {
  content: "\ea00";
}
.icon-abb_jog-planar_32:before {
  content: "\ea01";
}
.icon-abb_software-program-and-modules_32:before {
  content: "\ea02";
}
.icon-abb_software-program-editor_32:before {
  content: "\ea03";
}
.icon-abb_software-program_32:before {
  content: "\ea04";
}
.icon-abb_program-pointer_32:before {
  content: "\ea05";
}
.icon-abb_rapid-tasks_32:before {
  content: "\ea06";
}
.icon-abb_jog-reorient_32:before {
  content: "\ea07";
}
.icon-abb_reset-user-data_32:before {
  content: "\ea09";
}
.icon-abb_restore-backup_32:before {
  content: "\ea0a";
}
.icon-abb_select_32:before {
  content: "\ea0b";
}
.icon-abb_software-routins_32:before {
  content: "\ea0c";
}
.icon-abb_network-signal-strength-level-1_32:before {
  content: "\ea0d";
}
.icon-abb_network-signal-strength-level-2_32:before {
  content: "\ea0e";
}
.icon-abb_network-signal-strength-level-3_32:before {
  content: "\ea0f";
}
.icon-abb_io-signals_32:before {
  content: "\ea10";
}
.icon-abb_software-resources_32:before {
  content: "\ea11";
}
.icon-abb_start-installer_32:before {
  content: "\ea12";
}
.icon-abb_step-in_32:before {
  content: "\ea13";
}
.icon-abb_step-out_32:before {
  content: "\ea14";
}
.icon-abb_step-over_32:before {
  content: "\ea15";
}
.icon-abb_touch_32:before {
  content: "\ea16";
}
.icon-abb_video_32:before {
  content: "\ea17";
}
.icon-abb_turn-clockwise_32:before {
  content: "\ea18";
}
.icon-abb_turn-clockwise-off-target-speed_32:before {
  content: "\ea19";
}
.icon-abb_turn-counter-clockwise-off-target-speed_32:before {
  content: "\ea1a";
}
.icon-abb_add-drives_32:before {
  content: "\ea1b";
}
.icon-abb_bearing_32:before {
  content: "\ea1d";
}
.icon-abb_cloud_32:before {
  content: "\ea1e";
}
.icon-abb_delete-drives_32:before {
  content: "\ea1f";
}
.icon-abb_download-from-cloud_32:before {
  content: "\ea20";
}
.icon-abb_drive-connected_32:before {
  content: "\ea21";
}
.icon-abb_drives-error_32:before {
  content: "\ea22";
}
.icon-abb_fieldbus_32:before {
  content: "\ea23";
}
.icon-abb_generator_32:before {
  content: "\ea24";
}
.icon-abb_maximum-value_32:before {
  content: "\ea26";
}
.icon-abb_minimum-value_32:before {
  content: "\ea27";
}
.icon-abb_not-allowed_32:before {
  content: "\ea28";
}
.icon-abb_powertrain_32:before {
  content: "\ea29";
}
.icon-abb_prevent-turning-fault_32:before {
  content: "\ea2a";
}
.icon-abb_target-value-range_32:before {
  content: "\ea2b";
}
.icon-abb_thumbnail-view_32:before {
  content: "\ea2c";
}
.icon-abb_train_32:before {
  content: "\ea2d";
}
.icon-abb_turn-counter-clockwise_32:before {
  content: "\ea2e";
}
.icon-abb_wheel_32:before {
  content: "\ea2f";
}
.icon-abb_wifi-network-disconnected_32:before {
  content: "\ea31";
}
.icon-abb_write-to-default_32:before {
  content: "\ea32";
}
.icon-abb_write-to-default-disabled_32:before {
  content: "\ea33";
}
.icon-abb_caret-down_32:before {
  content: "\ea08";
}
.icon-abb_caret-left_32:before {
  content: "\ea1c";
}
.icon-abb_caret-right_32:before {
  content: "\ea30";
}
.icon-abb_caret-up_32:before {
  content: "\ea34";
}
.icon-abb_close-light_32:before {
  content: "\ea35";
}
.icon-abb_triangle-down_32:before {
  content: "\ea36";
}
.icon-abb_triangle-left_32:before {
  content: "\ea37";
}
.icon-abb_triangle-right_32:before {
  content: "\ea38";
}
.icon-abb_triangle-up_32:before {
  content: "\ea39";
}
.icon-abb_check-mark-light_32:before {
  content: "\ea3a";
}
.icon-abb_numbered-list_32:before {
  content: "\ea3b";
}
.icon-abb_text-bold_32:before {
  content: "\ea3c";
}
.icon-abb_text-italic_32:before {
  content: "\ea3d";
}
.icon-abb_text-underline_32:before {
  content: "\ea3e";
}
.icon-abb_call-outline_32:before {
  content: "\e9fd";
}
.icon-abb_location-outline_32:before {
  content: "\ea25";
}
.icon-abb_user-outline_32:before {
  content: "\ea3f";
}
