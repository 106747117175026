.Navigator {
  &-Item {
    /*
    * Line elements between the dots
    */

    /* Common attributes */
    flex: 1 1 100%;

    &:not(:first-child):before,
    &:not(:last-child):after {
      position: absolute;
      display: block;
      z-index: -1;
      width: 50%;
      width: calc(50% + 1px); /* To get rid of the subpixel gap */
      height: 0.125rem;
      /* pa3 + h1/2 - 0.125rem/2 = 1rem + 0.5rem - 0.0625rem = 1.4375rem*/
      top: 1.4375rem;
      content: '';
      /* TODO: colors as custom properties */
      background-color: rgb( 186, 186, 186 );   /* a.k.a. grey-30 */
    }

    /* Positioning */
    &:not(:first-child):before {
      left: 0;
      right: 50%;
    }

    &:not(:last-child):after {
      left: 50%;
      right: 0;
    }

    /* Coloring dark lines */
    &--is-visited:not(:first-child):before,
    &--is-active:not(:first-child):before,
    &--next-is-visited:not(:last-child):after {
      background-color: rgb( 104, 104, 104 ); /* a.k.a. grey-60 */
    }

    /*
     * Circle around the last item
     */

    &:last-child:after {
      position: absolute;
      display: block;
      content: '';
      top: 0.5rem;
      left: -99999px;
      left: calc(50% - 1rem);
      width: 1.5rem;
      height: 1.5rem;
      background-color: #ffffff;
      border-radius: 100%;
      z-index: -1;
      border: 0.25rem rgb(186, 186, 186) solid;
    }

    // Active color is red
    &--is-visited:last-child:after {
      border-color: rgb(104, 104, 104); /* a.k.a. grey-60 */
    }
    &--is-active:last-child:after {
      border-color: rgb(240, 48, 64); /* a.k.a. red */
    }
  }
}
