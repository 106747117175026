/*
  TODO: Determine the priority of CSS files.
  NOTE: Putting these component imports along with for e.g.
  the components that use these such as Toggle and Dropdown
  causes undesired rule conflicts since that would put the
  component style rules after the functional helpers which
  means that the component styles would take over
*/



// External components
@import url("rc-checkbox/assets/index.css");

// System rules
@import url("./designSystem/abbvoice-font.scss");
@import url("./designSystem/abb-icons.css");
@import url("./designSystem/reset.css");
@import url("./designSystem/tachyons.css");

// ABB CommonUX styles
@import url("@abb/abb-common-ux-react/styles.css");

html, body {
  font-size: 16px;
  background-color: #f6f6f6;
  scrollbar-gutter: stable;
}

/* Design system extension */
/*
  TODO: Generate design system CSS ourselves, so we can customize the output.
  For e.g. we need the following classes included.
  NOTE: For now, make sure the scales follow `tachyons.config.json`
 */

.font-abb-voice {
  /* TODO: Copied from abb.com, specify fonts better */
  font-family: "ABBvoice", Verdana, Arial, sans-serif;
}

.abb-link {
  color: var(--color-grey-90);
  &:hover, &:focus {
    color: var(--color-grey-100);
  }
}

.abb-link-no-underline {
  color: var(--color-grey-90);
  text-decoration: none;
  &:hover, &:focus {
    color: var(--color-grey-100);
    text-decoration: underline;
  }
}

.events-auto { pointer-events: auto }
.events-none { pointer-events: none }

.top-100 { top: 100%; }

.h0 { height: 0; }

.w6 { width: 32rem; }
.w7 { width: 64rem; }
.w8 { width: 128rem; }

.min-w1 { min-width: 1rem; }
.min-w2 { min-width: 2rem; }
.min-w3 { min-width: 4rem; }
.min-w4 { min-width: 8rem; }
.min-w5 { min-width: 16rem; }
.min-w6 { min-width: 32rem; }
.min-w7 { min-width: 64rem; }
.min-w8 { min-width: 128rem; }
.min-w-33 { min-width: 33.333333%; }
.min-w-50 { min-width: 50%; }
.min-w-100 { min-width: 100%; }

.min-h1 { min-height: 1rem; }
.min-h2 { min-height: 2rem; }
.min-h3 { min-height: 4rem; }
.min-h4 { min-height: 8rem; }
.min-h5 { min-height: 16rem; }

.vs1 > * { margin-bottom: 0.25rem; }
.vs2 > * { margin-bottom: 0.5rem; }
.vs3 > * { margin-bottom: 1rem; }
.vs4 > * { margin-bottom: 2rem; }
.vs5 > * { margin-bottom: 4rem; }

.hs1 > * + * { margin-left: 0.25rem; }
.hs2 > * + * { margin-left: 0.5rem; }
.hs3 > * + * { margin-left: 1rem; }
.hs4 > * + * { margin-left: 2rem; }
.hs5 > * + * { margin-left: 4rem; }

.bs0 { border-spacing: 0; }
.bs1 { border-spacing: 0.5rem; }
.bs2 { border-spacing: 1rem; }
.bs3 { border-spacing: 2rem; }
.bs4 { border-spacing: 4rem; }
.bsv1 { border-spacing: 0 0.5rem; }
.bsv2 { border-spacing: 0 1rem; }
.bsv3 { border-spacing: 0 2rem; }
.bsv4 { border-spacing: 0 4rem; }
.bsh1 { border-spacing: 0.5rem 0; }
.bsh2 { border-spacing: 1rem 0; }
.bsh3 { border-spacing: 2rem 0; }
.bsh4 { border-spacing: 4rem 0; }

@media screen and (min-width: 30em) {
  .w6-s { width: 32rem; }
  .w7-s { width: 64rem; }
  .w8-s { width: 128rem; }

  /* TODO: rest of these */
}

@media screen and (min-width: 48em) {
  .w6-m { width: 32rem; }
  .w7-m { width: 64rem; }
  .w8-m { width: 128rem; }

  .min-w1-m { min-width: 1rem; }
  .min-w2-m { min-width: 2rem; }
  .min-w3-m { min-width: 4rem; }
  .min-w4-m { min-width: 8rem; }
  .min-w5-m { min-width: 16rem; }
  .min-w6-m { min-width: 32rem; }
  .min-w7-m { min-width: 64rem; }
  .min-w8-m { min-width: 128rem; }
  .min-w-33-m { min-width: 33.333333%; }
  .min-w-50-m { min-width: 50%; }
  .min-w-100-m { min-width: 100%; }


  .min-h1-m { min-height: 1rem; }
  .min-h2-m { min-height: 2rem; }
  .min-h3-m { min-height: 4rem; }
  .min-h4-m { min-height: 8rem; }
  .min-h5-m { min-height: 16rem; }

  .vs1-m > * { margin-bottom: 0.25rem; }
  .vs2-m > * { margin-bottom: 0.5rem; }
  .vs3-m > * { margin-bottom: 1rem; }
  .vs4-m > * { margin-bottom: 2rem; }
  .vs5-m > * { margin-bottom: 4rem; }

  .hs1-m > * + * { margin-left: 0.25rem; }
  .hs2-m > * + * { margin-left: 0.5rem; }
  .hs3-m > * + * { margin-left: 1rem; }
  .hs4-m > * + * { margin-left: 2rem; }
  .hs5-m > * + * { margin-left: 4rem; }

  .bs0-m { border-spacing: 0; }
  .bs1-m { border-spacing: 0.5rem; }
  .bs2-m { border-spacing: 1rem; }
  .bs3-m { border-spacing: 2rem; }
  .bs4-m { border-spacing: 4rem; }
  .bsv1-m { border-spacing: 0 0.5rem; }
  .bsv2-m { border-spacing: 0 1rem; }
  .bsv3-m { border-spacing: 0 2rem; }
  .bsv4-m { border-spacing: 0 4rem; }
  .bsh1-m { border-spacing: 0.5rem 0; }
  .bsh2-m { border-spacing: 1rem 0; }
  .bsh3-m { border-spacing: 2rem 0; }
  .bsh4-m { border-spacing: 4rem 0; }
}

@media screen and (min-width: 64em) {
  .w6-l { width: 32rem; }
  .w7-l { width: 64rem; }
  .w8-l { width: 128rem; }

  .min-w1-l { min-width: 1rem; }
  .min-w2-l { min-width: 2rem; }
  .min-w3-l { min-width: 4rem; }
  .min-w4-l { min-width: 8rem; }
  .min-w5-l { min-width: 16rem; }
  .min-w6-l { min-width: 32rem; }
  .min-w7-l { min-width: 64rem; }
  .min-w8-l { min-width: 128rem; }
  .min-w-33-l { min-width: 33.333333%; }
  .min-w-50-l { min-width: 50%; }
  .min-w-100-l { min-width: 100%; }

  .min-h1-l { min-height: 1rem; }
  .min-h2-l { min-height: 2rem; }
  .min-h3-l { min-height: 4rem; }
  .min-h4-l { min-height: 8rem; }
  .min-h5-l { min-height: 16rem; }

  .vs1-l > * { margin-bottom: 0.25rem; }
  .vs2-l > * { margin-bottom: 0.5rem; }
  .vs3-l > * { margin-bottom: 1rem; }
  .vs4-l > * { margin-bottom: 2rem; }
  .vs5-l > * { margin-bottom: 4rem; }

  .hs1-l > * + * { margin-left: 0.25rem; }
  .hs2-l > * + * { margin-left: 0.5rem; }
  .hs3-l > * + * { margin-left: 1rem; }
  .hs4-l > * + * { margin-left: 2rem; }
  .hs5-l > * + * { margin-left: 4rem; }

  .bs0-l { border-spacing: 0; }
  .bs1-l { border-spacing: 0.5rem; }
  .bs2-l { border-spacing: 1rem; }
  .bs3-l { border-spacing: 2rem; }
  .bs4-l { border-spacing: 4rem; }
  .bsv1-l { border-spacing: 0 0.5rem; }
  .bsv2-l { border-spacing: 0 1rem; }
  .bsv3-l { border-spacing: 0 2rem; }
  .bsv4-l { border-spacing: 0 4rem; }
  .bsh1-l { border-spacing: 0.5rem 0; }
  .bsh2-l { border-spacing: 1rem 0; }
  .bsh3-l { border-spacing: 2rem 0; }
  .bsh4-l { border-spacing: 4rem 0; }
}

/* Extra cursors */
.cursor-auto { cursor: cursor; }
.cursor-default { cursor: default; }
.cursor-none { cursor: none; }

/* Extra lists */
.bullet-list { list-style: disc inside; }
.number-list { list-style: decimal inside; }

/* Extra tables */
.table-auto { table-layout: auto; }
.table-fixed { table-layout: fixed; }
.border-separate { border-collapse: separate}
.border-collapse { border-collapse: collapse}

/* Word break */
.break-all { word-break: break-all; }

/* Borders */
.br3 { border-radius: 1rem; }

/* Difference between bw1 and bw2 */
.ma05 { margin: .0625rem;; }

:root {
  --color-blue-10: rgb(224, 240, 255);
  --color-blue-20: rgb(197, 224, 250);
  --color-blue-30: rgb(158, 191, 247);
  --color-blue-40: rgb(119, 163, 252);
  --color-blue-50: rgb(76, 133, 255);
  --color-blue-60: rgb(51, 102, 255);
  --color-blue-70: rgb(42, 53, 255);
  --color-blue-80: rgb(44, 32, 212);
  --color-blue-90: rgb(22, 12, 156);
  --color-blue-100: rgb(8, 2, 97);
  --color-grey-00: rgb(255, 255, 255);
  --color-grey-10: rgb(235, 235, 235);
  --color-grey-20: rgb(219, 219, 219);
  --color-grey-30: rgb(186, 186, 186);
  --color-grey-40: rgb(159, 159, 159);
  --color-grey-50: rgb(132, 132, 132);
  --color-grey-60: rgb(104, 104, 104);
  --color-grey-70: rgb(82, 82, 82);
  --color-grey-80: rgb(61, 61, 61);
  --color-grey-90: rgb(31, 31, 31);
  --color-grey-100: rgb(15, 15, 15);
  --color-yellow: rgb(255, 216, 0);
  --color-orange: rgb(255, 115, 0);
  --color-red: rgb(240, 48, 64);
  --color-magenta: rgb(203, 43, 213);
  --color-cyan: rgb(20, 240, 240);
  --color-green: rgb(12, 169, 25);
  --color-blue: rgb(0, 82, 255);
}

@media print {
  .no-print { display: none; }

  a {
    text-decoration: underline !important;
  }
}
