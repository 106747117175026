.ComparisonTable {
  overflow-x: auto;
  display: grid;
  //justify-items: center;
  grid-auto-flow: column;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(5, auto);
  gap: 10px;

  & > * {
    min-width: 200px;
    max-width: 400px;
  }
}

.Details {
  display: grid;
  grid-auto-flow: row dense;
  grid-template-columns: 1fr 1fr 2fr;
  grid-template-rows: min-content;
  height: 300px;
  align-items: start;
  gap: 10px;
}

.DetailsImage {
  object-fit: scale-down;
  max-height: 300px
}